import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Events } from '@ionic/angular';


import { IProperty } from '../interfaces/property.interface';


@Injectable()
export class PropertyService {

  private readonly configService: ConfigService = new ConfigService(); 

  constructor(
    private readonly httpClient: HttpClient,
    private readonly events: Events
  ) {

  }

//*******ML calls ******/
getDaysonMarket(property: any): Observable<IProperty> {
  //this is the placeholder object.
  let listprice: number = (property.eccoval.priceadjustment ? property.eccoval.priceadjustment : 0)
  let sq_feet: number = (property.features.home_square_footage ? property.features.home_square_footage : 0);
    //return this.httpClient.post('wf/ml/daysonmarket', {
    return this.httpClient.post<IProperty>('sourcedata/estimatedom', {
      'property': property,
      'overlay': 
      { 
        PricePerSquareFoot: listprice/sq_feet,
        ListPrice: listprice
      }
    }); 
}


  save(property: any): Observable<IProperty> {
    // default the cma price to the List Price from MLS /klb
    if (property.ListPrice != null && property.eccoval == null) {
      property.eccoval = { 'cmaprice': 0 };
    }
    if (!!property._id){
      return this.httpClient.post<IProperty>('property/update', property);
    } else {
      return this.httpClient.post<IProperty>('property/create', property);
    }
  }

  uploadimage(imageData: any): Observable<IProperty> {
/*    let formData = new FormData();
    formData.append('somekey', 'some value')
    //formData.append('files', image);
    formData.append('files[]', image.rawFile, image.name);
*/
    let headers = new HttpHeaders();
    //headers.append('Accept', 'application/json');
    //headers.append("Content-Type", "application/json");
    headers.append("Content-Type", 'multipart/form-data');

    return this.httpClient.post<IProperty>('property/image', imageData, { headers: headers });
  }

  getImage(imgId){
    if (!imgId){
      return 'assets/img/logo.png';
    } else if (imgId.startsWith('http')) {
      return imgId.replace('http://', 'https://');
    } else {
      return this.configService.getSvcURL("property/image?imgId=" + imgId)
    }
  }

  delete(property: any): Observable<IProperty> {
    if (!property.id){
      return this.httpClient.get<IProperty>('property/delete?id='+ property);
    } else {
      return this.httpClient.get<IProperty>('property/delete?id='+ property.id);
    }
  }

  findAll(): Observable<IProperty[]> {
    return this.httpClient.get<IProperty[]>('property/');
  }

  findById(id: any): Observable<IProperty>{
    return this.httpClient.get<IProperty>('property/byid?id='+ id);
  }

  propertyVerified(property: any){
    return  (!property.geocode || !property.geocode.resolving);
  }

  defaultProperty(){
    return {
      empty: true,
      location: 'Loading...',
      images: [],
      features: {
        home_area: -1,
        lot_area: -1
      },
      geocode: {},
      eccoval: {
        cmaprice: 0,
        priceadjustment: 0,
        pricerecomended: 0,
        minprice: 0,
        maxprice: 0,
        modifiers: []
      }
    };
  }
}
