import { Component, NgModule, OnInit } from "@angular/core";
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { LoginPage } from "../login/login";
import { HomePage } from "../home/home";
import { AuthService } from "../../services/auth.service";
import { AppService } from "../../services/appservice.service";
import { RegistrationCompleteModalComponent } from "../modal/registration-complete-modal/registration-complete-modal.component";
import { environment } from '../../../environments/environment';
import { UserAdminService } from "src/app/services/user.service";
import { VideoModal } from "../video-modal/video-modal";
import { AppInfoService } from "src/app/services/appinfo.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var SqPaymentForm: any; //magic to allow us to access the SquarePaymentForm lib

@Component({
  selector: 'page-register',
  templateUrl: 'register.html',
  styleUrls: ['./register.scss']
})
export class RegisterPage implements OnInit {

  public mail: string;
  public password: string;
  public firstname: string;
  public lastname: string;
  public address1: string;
  public city: string;
  public state: string;
  public zip: string;
  public appservices = [];
  public serviceURL = "";
  public paymentForm: any;
  public videoDone = false;

  public cardErrors: Boolean = false;
  private modalCtrl: any;

  private videoModalCtrl: any;
  private welcomeVideo: SafeResourceUrl;

  constructor(public nav: NavController,
    public auth: AuthService,
    public toastCtrl: ToastController,
    public modalController: ModalController,
    private readonly appService: AppService,
    private readonly userService: UserAdminService,
    private readonly appInfoService: AppInfoService,
    private sanitizer: DomSanitizer) {
  }

  /*trustedVideo() {
    if (this.welcomeVideo) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.welcomeVideo);
    } else {
      return undefined;
    }
  }*/

  ngOnInit() {
    this.initPaymenForm();
  }

  // register and go to home page
  register() {
    if (this.serviceURL && this.serviceURL.length) {
      this.saveService(this.serviceURL);
    } else {
      this.saveService('http://localhost:9000/api/'); // hack for localhost
    }

    this.paymentForm.requestCardNonce();

    /*this.auth.signUp(this.mail, this.password, this.firstname, this.lastname).subscribe(
      (loggedin: Boolean) => {
        if (loggedin){
          this.nav.navigateRoot('home');
        } else {
          this.nav.setRoot(LoginPage);
        }
    });*/
  }

  initPaymenForm() {
    this.videoDone = true;
    setTimeout(() => {
      this.auth.getSquareWebToken().subscribe(webToken => {
        const me = this; // dereference 'this' for usage in the inner code below
        this.paymentForm = new SqPaymentForm({
          // Initialize the payment form elements
  
          //TODO: Replace with prod app ID for production
          applicationId: webToken.squareApplicationId,
          autoBuild: false,
          // Initialize the credit card placeholders
          card: {
            elementId: 'sq-card',
          },
          // SqPaymentForm callback functions
          callbacks: {
            cardNonceResponseReceived: function (errors, nonce, cardData) {
              console.log(errors, nonce, cardData);
  
              if (errors) {
                me.cardErrors = true;
                // Log errors from nonce generation to the browser developer console.
                let etext = "";
                errors.forEach(function (error) {
                  etext += ('  ' + error.message);
                });
                alert('Encountered errors, check browser developer console for more details - ' + etext);
                return;
              } else {
                me.cardErrors = false;
              }
  
              // send this stuff to the api so we can build some square records
              me.auth.signUp(me.mail, me.password, me.firstname, me.lastname, nonce, me.address1, me.city, me.state, me.zip)
                .subscribe(
                  async (loggedin: Boolean) => {
                    me.modalCtrl = await me.modalController.create(
                      {
                        component: RegistrationCompleteModalComponent
                      });
  
  
                    me.modalCtrl.onDidDismiss().then(() => {
                      if (loggedin) {
                        me.nav.navigateRoot('home');
                      } else {
                        // thank the user then send them home       
                        me.nav.navigateRoot('');
                      }
                    });
  
                    await me.modalCtrl.present();
                  },
                  async (err) => {
                    // this is actually ok
                    console.log('got signup result: ', err, me);
                    me.modalCtrl = await me.modalController.create(
                      {
                        component: RegistrationCompleteModalComponent
                      });
  
                    me.modalCtrl.onDidDismiss().then(() => {
                      me.nav.navigateRoot('home');
                    });
  
                    await me.modalCtrl.present();
                  });
            }
          }
        });
  
        this.paymentForm.build();
      });  
    })
  }

  loadServices(username) {
    if (username && username.ngControl && !username.ngControl.invalid) {
      if (this.appService.needsSvcURL()) {
        return this.appService.listSvcURL(username.value).subscribe((svcs: any[]) => {
          //if we get more than one service then setup the end-user selection
          if (svcs.length > 1) {
            this.appservices = svcs;
            if (this.appservices && this.appservices.length) {
              this.serviceURL = this.appservices[0].baseurl;
            }
            //save the first one
            return svcs;
          } else {
            //Otherwise just set the service.
            this.appService.saveSvcURL('http://localhost:9000/api/');
          }
        });
      } else {
        this.serviceURL = this.appService.getDetaulSvcUrl();
      }
    }
  }

  saveService(service) {
    if (service && service.length) {
      this.appService.saveSvcURL(service);
    }
  }

  // go to login page
  login() {
    this.nav.navigateRoot('');
  }
}
