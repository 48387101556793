import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsPage } from "./pages/settings/settings";

import { HomePage } from "./pages/home/home";
import { LoginPage } from "./pages/login/login";
import { NotificationsPage } from "./pages/notifications/notifications";
import { RegisterPage } from "./pages/register/register";
import { PropertyDetailPage } from "./pages/property-detail/property-detail";
//import {PropertylistPage} from "./pages/propertylist/propertylist";
import { LocalWeatherPage } from "./pages/local-weather/local-weather";
import { PictureLaodPage } from "./pages/pictureload/pictureload";
import { CreatePropertyPage } from "./pages/createproperty/createproperty";
import { OrgAdminPage } from "./pages/admin/org-admin";
import { ModifierAdminPage } from "./pages/admin/modifier-admin";
import { ProfilePage } from './pages/profile/profile';
import { AppLayoutComponent } from './pages/applayout/applayout.component'
import { SimulatorComponent } from './pages/simulator/simulator.component';


/*
const routes: Routes = [
  { path: '', component: LoginPage },
  { path: 'home', component: HomePage, }, 
  { path: 'register', component: RegisterPage },
  // { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'settings', component: SettingsPage },
  { path: 'profile', component: ProfilePage },
  { path: 'search', component: SearchLocationPage },
  { path: 'create', component: CreatePropertyPage },
  { path: 'property/:id', component: PropertyDetailPage },
  { path: 'admin/org'}
  //CreatePropertyPage
  // { path: 'home-results', loadChildren: './pages/home-results/home-results.module#HomeResultsPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}

*/
const routes: Routes = [
  { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'register', component: RegisterPage },
  { path: 'edit-profile', component: ProfilePage },
  { path: 'home', component: HomePage },
  { path: 'property/:id', component: PropertyDetailPage },
  { path: 'picture', component: PictureLaodPage},
  { path: 'create', component: CreatePropertyPage},
  { path: 'admin/org', component: OrgAdminPage },
  { path: 'admin/lboadmin', component: ModifierAdminPage },
  { path: 'simulator', component: SimulatorComponent }
];

    /*
{path: '/admin/org', component: OrgAdminPage, icon: 'person', roles:['admin']},
      {path: '/admin/lboadmin', component: ModifierAdminPage, icon: 'person', roles:['admin']}

    */

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
