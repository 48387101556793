import { Component, Input, NgModule } from "@angular/core";
import {NavParams, ModalController} from '@ionic/angular';
import {PropertyService} from "../../services/property.service";
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'property-details-modal',
  templateUrl: 'property-details-modal.html',
  styleUrls: ['./property-details-modal.scss'],
})

export class PropertyDetailsModal {

  public property: any;

  constructor(public viewCtrl: ModalController, public params: NavParams, public propertyService: PropertyService, public toastCtrl: ToastController) {
    this.property = params.get("property");
  }

  addProperty() {
    console.log('params: ', this.property);

    this.propertyService.save(this.property).subscribe((property) => {
      this.close();
      this.toastCtrl.create({
        message: 'Property has been added Successfully!',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
    });
  }

  close() {
    this.viewCtrl.dismiss({
      'dismissed': true
    });
  }

  private formatAddress(element: any){
    if (element.formatted_address){
      return element.formatted_address;
    } else {
      return element.StreetNumberNumeric + ' ' + element.StreetName + ' ' + element.City + ', ' + element.StateOrProvince + ' ' + element.PostalCode;
    }
  }

  getDescription(property) {
    return (property.PublicRemarks && property.PublicRemarks.left ? property.PublicRemarks.left(25) + '...' : property.PropertySubType);
  }

}
