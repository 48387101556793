import { Component, NgModule, OnInit } from "@angular/core";
import { NavController, PopoverController, ModalController, MenuController } from '@ionic/angular';
import { PropertyService } from "../../services/property.service";
import { UserAdminService } from "../../services/user.service";
import { ISearchParams, SourcedataService } from "../../services/sourcedata.service";
import { LocationService } from "../../services/location.service";
import { PropertyDetailsModal } from "../property-details-modal/property-details-modal.component";
import { Title } from "@angular/platform-browser";
import { Events } from '@ionic/angular';
import { AppInfoService } from "src/app/services/appinfo.service";


@Component({
  selector: 'page-home',
  templateUrl: 'home.html',
  styleUrls: ['./home.scss'],
})

export class HomePage implements OnInit {
  // search condition
  public search = {
    name: "New York, United States",
    date: new Date().toISOString()
  }

  public searchText: string = "";
  public location: any;
  public properties: any;
  public searchProperties: any;
  public searchparams: ISearchParams = { 'empty': { display: '', type: '', key: '', value: '' } };
  public staticsearch = {
    streetname: "empty",
    streetnumber: "empty",
    city: "empty",
    postalcode: "empty",
    listingid: "empty"
  };

  loadstatus = 'Loading your properties...';

  constructor(public nav: NavController, public popoverCtrl: PopoverController,
    public propertyService: PropertyService, public userService: UserAdminService,
    public sourcedataService: SourcedataService, public locationService: LocationService, public modalController: ModalController,
    public menuCtrl: MenuController, public titleService: Title, 
    private readonly events: Events,
    public appInfoService: AppInfoService) {    
    sourcedataService.getParamFields().subscribe(
      (params: ISearchParams) => {
        this.staticsearch = {
          streetname: "StreetName",
          streetnumber: "StreetNumberNumeric",
          city: "City",
          postalcode: "PostalCode",
          listingid: "ListingId"
        };

        if (params) {
          this.searchparams = params;
        }
      });

  }
  ngOnInit(): void {
    this.loadProperties();
  }

  loadProperties() {
    this.loadstatus = 'Loading your properties...';
    this.propertyService.findAll().subscribe(
      (properties: any) => {
        console.log(properties);
        this.properties = properties;
      }, err => {
        this.loadstatus = 'Error loading your properties.';
        this.events.publish('service.exception', err);
        this.properties = undefined;
      });
  }

  // ionViewWillEnter() {
  //   // this.search.pickup = "Rio de Janeiro, Brazil";
  //   // this.search.dropOff = "Same as pickup";
  //   this.storage.get('pickup').then((val) => {
  //     if (val === null) {
  //       this.search.name = "";
  //     } else {
  //       this.search.name = val;
  //     }
  //   }).catch((err) => {
  //     console.log(err)
  //   });
  // }

  // go to result page
  doSearch() {
    //  this.nav.push(PropertylistPage);
  }

  // choose place
  choosePlace(from) {
    this.nav.navigateForward('search');
  }


  // to go account page
  goToAccount() {
    this.nav.navigateForward('settings');
  }

  /*presentNotifications(myEvent) {
    console.log(myEvent);
    let popover = this.popoverCtrl.create(NotificationsPage);
    popover.then(ctl => {
      ctl.present({
        ev: myEvent
      });
    })
  }*/

  // view trip detail
  viewDetail(id, event) {
    if (event.srcElement.id !== "delete") {
      this.nav.navigateForward(['property', id]);
    }
  }

  deleteProperty(id) {
    this.propertyService.delete(id).subscribe(
      (deleted: any) => {
        this.propertyService.findAll().subscribe(
          (properties: any) => {
            this.properties = properties;
          })
      })
  }

  public searchSourceData() {
    this.sourcedataService.getProperties(this.searchparams).subscribe(
      (property: any[]) => {
        this.searchProperties = property;
      });
  }

  private formatAddress(element: any) {
    if (element.formatted_address) {
      return element.formatted_address;
    } else {
      return element.StreetNumberNumeric + ' ' + element.StreetName + ' ' + element.City + ', ' + element.StateOrProvince + ' ' + element.PostalCode;
    }
  }

  private modalCtrl: any;

  public async presentPropertyDetailsModal(property: any) {

    this.modalCtrl = await this.modalController.create(
      {
        component: PropertyDetailsModal,
        componentProps: { property: property }
      });

    this.modalCtrl.onDidDismiss().then((detail: any) => {
      if (detail !== null) {
        this.loadProperties();
      }
    });

    return await this.modalCtrl.present();
  }

  createProperty() {
    this.nav.navigateForward('create');
  }

  getDescription(property) {
    return (property.PublicRemarks && property.PublicRemarks.left ? property.PublicRemarks.left(25) + '...' : property.PropertySubType);
  }
}

//
