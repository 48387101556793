import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserlogin } from '../interfaces/userlogin.interface';
import { Events } from '@ionic/angular';
import {IUser} from '../interfaces/user.interface';

@Injectable()
export class AuthService {


  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly jwtHelper: JwtHelperService ,
    private readonly events: Events
  ) {

  }

  signUp(email: string, password: string, firstname: string, lastname: string, nonce: string, address1: string,
         city: string, state: string, zip: string): Observable<Boolean> {
    return this.httpClient.post('auth/local/signup', {
      email,
      password,
      firstname,
      lastname,
      address1,
      city,
      state,
      zip,
      nonce
    }).pipe(map((userlogin: IUserlogin) => {
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    }));
  }

  signIn(email: string, password: string, token?: string): Observable<Boolean> {
    /*this.httpClient.post('auth/local/signin'), {
      email,
      password
    }).subscribe((token: IToken) => {
      localStorage.setItem('token', token.token);
    });
*/

    return this.httpClient.post('auth/local/signin', {
      email,
      password,
      token
    }).pipe(map((userlogin: IUserlogin) => {
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    }));
  }

  changePassword(email: string, password: string, token: string): Observable<Boolean> {

    return this.httpClient.post('auth/local/changepass', {
      email,
      password,
      token
    }).pipe(map((userlogin: IUserlogin) => {
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    }));
  }

  resetPassword(email: string, password: string): Observable<Boolean> {
      return this.httpClient.put('auth/local/resetpass', {
        email,
        password
      }).pipe(map((userlogin: IUserlogin) => {
        if (userlogin && userlogin.token) {
          localStorage.setItem('token', userlogin.token.token);
          localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
          this.events.publish('user:profile', userlogin.user);
        }
        return !!this.getToken() && !this.jwtHelper.isTokenExpired();
      }));
  }

  requestFacebookRedirectUri(): Observable<any> {
    return this.httpClient.get('auth/facebook/uri');
  }

  facebookSignIn(code: string): Observable<any> {
    return this.httpClient.post('auth/facebook/signin', { code });
  }

  requestTwitterRedirectUri(): Observable<any> {
    return this.httpClient.get('auth/twitter/uri');
  }

  twitterSignIn(oauth_token: string, oauth_verifier: string): Observable<any> {
    return this.httpClient.post('auth/twitter/signin', { oauth_token, oauth_verifier });
  }

  requestGoogleRedirectUri(): Observable<any> {
    return this.httpClient.get('auth/google/uri');
  }

  googleSignIn(code: string): Observable<any> {
    return this.httpClient.post('auth/google/signin', { code });
  }

  getProtected(): Observable<any> {
    return this.httpClient.get('auth/authorized');
  }

  getToken(): string {
    try {
      const user: any = JSON.parse(localStorage.getItem('userprofile'));
      // userprofile
      if (user) {
        this.events.publish('user:profile', user);
      }
      return localStorage.getItem('token');
    } catch (e) {
      return null;
    }
  }

  getUser(): IUser {
    try {
      const user: any = JSON.parse(localStorage.getItem('userprofile'));
      // userprofile
      if (user) {
        this.events.publish('user:profile', user);
      }
      return user;
    } catch (e) {
      return null;
    }
  }

  signOut(): void {
    localStorage.clear();
    this.events.publish('user:logout');
  }

  isAuthenticated(): Observable<Boolean> {
    // return true;
    // return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    return this.getProtected().pipe(map(res => {
      return (res ? !!this.getToken() && !this.jwtHelper.isTokenExpired() && res.authorized : false) ;
    }));
  }

  getSquareWebToken(): Observable<any> {
    return this.httpClient.get<any>('users/squarewebtoken');
  }


}
