import { Component, ViewChild } from '@angular/core';

import { Platform, NavController, Events, ToastController, MenuController, IonNav } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Pages } from './interfaces/pages';

import { AuthService } from './services/auth.service';
import { AppService } from './services/appservice.service';

import _ from 'lodash';
import { ScriptService } from './services/script.service';
import { Subscriber } from 'rxjs';

// managed by build scripts.
import { AppInfoService } from 'src/app/services/appinfo.service';


export interface SecurePages extends Pages {
  roles?: Array<string>;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(IonNav) nav: IonNav;

  showMenu = false;

  public appPages: Array<SecurePages> = [];
  public user: any = {
    firstname: 'none',
    lastname: 'none',
    roles: [],
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public auth: AuthService,
    public events: Events,
    public toastController: ToastController,
    public appService: AppService,
    public menuCtrl: MenuController,
    public scriptService: ScriptService,
    public appInfoService: AppInfoService,
  ) {

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.menuCtrl.close();
      this.menuCtrl.enable(false, 'main');
      this.auth.getSquareWebToken().subscribe(webToken => {
        this.scriptService.load('square-' + webToken.squareEnv);
      });
      this.splashScreen.hide();
    }, (err) => {
      console.log('error bootstraping application: ', err);
    });

    this.events.subscribe('user:logout', () => {
      this.appPages = [];
      this.navCtrl.navigateRoot('');
    });

    this.events.subscribe('user:profile', (user: any) => {
      this.user = user;
      // do this after login so we get the security applied.
      this.loadMenu(user);
    });

    //// events.publish('service.exception', err);
    this.events.subscribe('service.exception', (err: any) => {
      // this.error = err;
      if (this.appInfoService.svcVersion.online) {
        return this.presentErrors(this.getErrMsg(err));
      }
    });

    this.auth.isAuthenticated().subscribe(
      (loggedin: Boolean) => {
        if (loggedin) {
          this.menuCtrl.enable(true, 'main');
          this.navCtrl.navigateRoot('home');
        } else {
          this.menuCtrl.enable(false, 'main');
          this.auth.signOut();
        }
      });

  }

  private getErrMsg(error) {
    if (error.name === 'HttpErrorResponse') {
      const err = (error.error ? error.error : error);
      return (err.message.error ? err.message.error : err.message);
    } else if (error.statusCode != null) {
      return (error.message.error ? error.message.error : error.message);
    } else {
      return (error.message ? error.message : error.error);
    }
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    this.menuCtrl.enable(false, 'main');
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.navCtrl.navigateRoot('');
  }

  async presentErrors(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 6000,
      position: 'top'
    });
    toast.present();
  }


  applyMenuSecurity(user, menuItems: Array<SecurePages>) {
    return menuItems.filter(function (menuItem) {
      return ((!menuItem.roles || menuItem.roles.length < 1) || (_.intersection(menuItem.roles, user.roles).length > 0));
    });
  }

  loadMenu(user) {
    this.menuCtrl.enable(true, 'main');
    this.appPages = this.applyMenuSecurity(user, [
      {
        title: 'Home',
        url: '/home',
        direct: 'root',
        icon: 'home'
      },
      {
        title: 'Organization Admin',
        url: '/admin/org',
        direct: 'forward',
        icon: 'person',
        roles: ['admin']
      },
      {
        title: 'Feature VALUmodifier Admin',
        url: '/admin/lboadmin',
        direct: 'forward',
        icon: 'cog',
        roles: ['admin']
      }
    ]);
  }

  disableMenu(url) {
    return window.location.pathname.startsWith(url);
  }

  reload() {
    return window.location.href;
  }

}
