import { Component, NgModule } from "@angular/core";
import { NavController, Events } from '@ionic/angular';
import { AuthService } from "../../services/auth.service";
import { UserAdminService } from "../../services/user.service";
import { ToastController } from '@ionic/angular';
import { IUser } from "../../interfaces/user.interface";
import { Title } from "@angular/platform-browser";

declare var SqPaymentForm: any; //magic to allow us to access the SquarePaymentForm lib

@Component({
  selector: 'user-profile',
  templateUrl: 'profile.html'
})
export class ProfilePage {
  email: string;
  firstname: string;
  lastname: string;
  zip: string;
  address: string;
  city: any;
  state: any;
  public paymentForm: any;
  public user: IUser;

  constructor(public nav: NavController,
    public events: Events,
    public auth: AuthService,
    public users: UserAdminService,
    public toastCtrl: ToastController,
    public userService: UserAdminService,
    public titleService: Title) {

    if (window.location.protocol === 'https:') {
      this.auth.getSquareWebToken().subscribe(webToken => {

        this.paymentForm = new SqPaymentForm({
          // Initialize the payment form elements
          //TODO: Replace with prod app ID for production from a legit location
          applicationId: webToken.squareApplicationId,
          autoBuild: false,
          // Initialize the credit card placeholders
          card: {
            elementId: 'sq-card',
          },
          // SqPaymentForm callback functions
          callbacks: {
            cardNonceResponseReceived: (errors, nonce, cardData) => {
              if (errors) {
                // Log errors from nonce generation to the browser developer console.
                errors.forEach(function (error) {
                  console.error('  ' + error.message);
                });
                alert('Encountered errors, check browser developer console for more details');
                return;
              }

              this.users.saveCard(nonce).subscribe((user) => {
                this.user = user;
                this.toastCtrl.create({
                  message: 'Credit Card has successfully been updated!',
                  duration: 3000,
                  position: 'top'
                }).then(ctl => {
                  ctl.present();
                });
              });
            }
          }
        });
        if (this.paymentForm) {
          this.paymentForm.build(); // build the square form
        }
      });
    } else {
      console.warn('Site not using SSL, SQUARE setup skipped.')
    }

    this.user = this.auth.getUser(); // trigger the user profile get
    if (!this.user.profile) {
      this.user.profile = { // if the user has no profile, make one (this is mainly for older user accounts)
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: null,
        phone: '',
        image: ''
      };
    }

  }

  saveProfile() {
    // save the user information
    this.users.save(this.user).subscribe((user) => {
      this.user = user;
      this.toastCtrl.create({
        message: 'User Information has been updated Successfully!',
        duration: 3000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      });
    });
  }

  saveBillingInformation() {
    // save the user information
    this.users.save(this.user).subscribe((user) => {
      this.user = user;
      this.toastCtrl.create({
        message: 'Billing Address has been updated Successfully!',
        duration: 3000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      })
    });
  }

  saveCCInformation() {
    if (this.paymentForm) {
      this.paymentForm.requestCardNonce();
    } else {
      this.toastCtrl.create({
        message: 'Payment form not initialized, skipping.',
        duration: 3000,
        position: 'top'
      }).then((ctl) => {
        ctl.present();
      });
    }
  }

  downloadInvoice() {
    // download the square invoice that was selected - this is a receipt object off the Charge Object
  }

}
