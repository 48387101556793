
export class FieldLabelConstants {

  public fieldLables: any = {
    "LotSizeAcres": "Lot Size (Acres)",
    "Appliances": "Appliances",
    "AppliancesYN": "Has Appliances",
    "BathroomTotalInteger": "Total Bathrooms",
    "BathroomsFull": "Count of Full Bathrooms",
    "BathroomsFullAndThreeQuarter": "Count of Full & 3/4 Bathrooms",
    "BathroomHalf": "Count of Half Bathrooms",
    "BedroomsTotal": "Total Number of Bedrooms",
    "City": "City",
    "ConstructionMaterials": "Construction Materials",
    "Cooling": "Cooling",
    "CoolingYN": "Has Cooling",
    "Country": "Country",
    "CountyOrParish": "County",
    "CurrentPrice": "Current Price",
    "EatingArea": "Eating Area",
    "FenceYN": "Fence",
    "FenceType": "Fence Type",
    "FireplaceYN": "Fireplace",
    "FireplaceFeatures": "Fireplace Features",
    "Flooring": "Type of Flooring",
    "Heating": "Heating",
    "HeatingYN": "Has Heating",
    "InteriorFeatures": "Interior Features",
    "LockBox": "Lock Box",
    "LaundryFeatures": "Laundry Features",
    "LaundryYN": "Has Laundry",
    "ListPrice": "List Price",
    "OriginalListPrice": "Original List Price",
    "LotFeatures": "Lot Features",
    "LotSizeSource": "Lot Size",
    "GarageSpaces": "Garage Space",
    "ParkingFeatures": "Parking Features",
    "ParkingTotal": "Parking Total",
    "ParkingYN": "Parking",
    "PoolFeatures": "Pool Features",
    "PoolPrivateYN": "Private Pool",
    "PropertyType": "Property Type",
    "PropertySudType": "Property Sub Type",
    "Roof": "Roof",
    "RoomType": "Room Type",
    "SecurityFeatures": "Security Type",
    "SprinklersYN": "Sprinklers",
    "LivingArea": "Living Area",
    "StateOrProvince": "State or Province",
    "Levels": "Levels",
    "StoriesTotal": "Stories Total",
    "ArchitecturalStyle": "Architectural Style",
    "Utilities": "Utilities",
    "View": "View",
    "ViewYN": "Has View",
    "YearBuilt": "Year Built",
    "PostalCode": "Postal Code"
  }



}
