import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from '../interfaces/user.interface';

@Injectable()
export class UserAdminService {


  constructor(
    private readonly httpClient: HttpClient
  ) {

  }

  save(user: any): Observable<IUser> {
    console.log('got user save: ', user);
    if (!!user._id){
      return this.httpClient.post<IUser>('users/profile-update', user);
    } else {
      // the user is not created this way, they self-register.
    }
  }

  saveCard(cardnonce: any): Observable<IUser> {
    //if (!!user._id){
    const card = { 'cardnonce': cardnonce};

    return this.httpClient.post<IUser>('users/update-card', card); // TODO: need to make this an actual object
    //} else {
      // the user is not created this way, they self-register.
    //}
  }

  getRecord(id: string): Observable<IUser> {
    return this.httpClient.get<IUser>('users/byid/' + id);
  }

/* --We may use these later to allow logos to be uploaded....
  uploadimage(imageData: any): Observable<Iuser> {
    let headers = new HttpHeaders();
    //headers.append('Accept', 'application/json');
    //headers.append("Content-Type", "application/json");
    headers.append("Content-Type", 'multipart/form-data');

    return this.httpClient.post('property/image', imageData, { headers: headers });
  }

  getImage(imgId){
    if (!imgId){
      return 'assets/img/logo.png';
    }
    return this.configService.getSvcURL("property/image?imgId=" + imgId)
  }

  */

  delete(user: any): Observable<IUser> {
    if (!user.id){
      return this.httpClient.get<IUser>('users/delete?id='+ user);
    } else {
      return this.httpClient.get<IUser>('users/delete?id='+ user.id);
    }
  }

  findAll(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>('users/');
  }

  findById(id: any): Observable<IUser>{
    return this.httpClient.get<IUser>('users/byid?id='+ id);
  }

}
