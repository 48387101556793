import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';

// Ecco specific
import { AgGridModule } from 'ag-grid-angular';

import { ModifierService } from "./services/modifier-service";
import { WeatherProvider } from "./services/weather";
import { CustomModifierService } from "./services/custommodifier-service";
import { AuthService } from "./services/auth.service";
import { ConfigService } from "./services/config.service";
import { LocationService } from "./services/location.service";
import { PropertyService } from "./services/property.service";
import { SourcedataService } from "./services/sourcedata.service";
import { OrgService } from "./services/org.service";
import { UserAdminService } from './services/user.service';
import { AppService } from "./services/appservice.service";
import { ScriptService } from './services/script.service';

import { SettingsPage } from "./pages/settings/settings";
import { HomePage } from "./pages/home/home";
import { NotificationsPage } from "./pages/notifications/notifications";
import { RegisterPage } from "./pages/register/register";
import { PropertyDetailPage } from "./pages/property-detail/property-detail";
//import {PropertylistPage} from "./pages/propertylist/propertylist";
import { LocalWeatherPage } from "./pages/local-weather/local-weather";
import { PictureLaodPage } from "./pages/pictureload/pictureload";
import { CreatePropertyPage } from "./pages/createproperty/createproperty";
import { OrgAdminPage } from "./pages/admin/org-admin";
import { ModifierAdminPage } from "./pages/admin/modifier-admin";
import { ModifierImportAdminPage } from "./pages/admin/modifier-import-modal/modifier-import-modal.component";


import { environment } from '../environments/environment';
import { PropertyFilterPipe } from "./pipes/property-filter-pipe";
import { PropertyDetailsModal } from "./pages/property-details-modal/property-details-modal.component";
import { ExtendedPropertyDetailsModal } from "./pages/extended-property-details-modal/extended-property-details-modal";
import { ModifierAddModal } from "./pages/admin/modifier-add-modal/modifier-add-modal.component";
import { ProfilePage } from "./pages/profile/profile";
import { FormsModule } from "@angular/forms";

import { AppLayoutComponent } from './pages/applayout/applayout.component';
import { ApplicationHttpClient } from './services/extended-http.service';
import { JwtModule, JWT_OPTIONS, JwtModuleOptions } from '@auth0/angular-jwt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RegistrationCompleteModalComponent } from './pages/modal/registration-complete-modal/registration-complete-modal.component';
import { SimulatorComponent } from './pages/simulator/simulator.component';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { AppInfoService } from './services/appinfo.service';
import { CookieModule } from 'ngx-cookie';
import { VideoModal } from './pages/video-modal/video-modal';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function tokenGetterFx(): string {
  return localStorage.getItem('token');
};

const jwtOptions: JwtModuleOptions = {
  'config': {
    tokenGetter: tokenGetterFx,
    allowedDomains: environment.jwt.whitelistedDomains,
    disallowedRoutes: environment.jwt.blacklistedRoutes
  }
};


@NgModule({
  declarations: [
    AppLayoutComponent,
    AppComponent,
    NotificationsComponent,
    HomePage,
    LocalWeatherPage,
    NotificationsPage,
    RegisterPage,
    PropertyDetailPage, 
    PictureLaodPage,
    CreatePropertyPage,
    OrgAdminPage,
    ModifierAdminPage,
    ProfilePage,
    PropertyDetailsModal,
    ExtendedPropertyDetailsModal,
    ModifierAddModal,
    ModifierImportAdminPage,
    SettingsPage,
    PropertyFilterPipe,
    RegistrationCompleteModalComponent,
    SimulatorComponent,
    VideoModal
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ImagePageModule,
    SearchFilterPageModule,
    AgGridModule.withComponents([]),
    FormsModule,
    JwtModule.forRoot(jwtOptions),
    IonicStorageModule.forRoot({
      name: environment.storagename,
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxCurrencyModule,
    NgxMaskModule.forRoot(),
    CookieModule.forRoot()
  ],
  entryComponents: [NotificationsComponent, HomePage, ModifierAddModal, SimulatorComponent, ExtendedPropertyDetailsModal, PropertyDetailsModal, RegistrationCompleteModalComponent, VideoModal],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ModifierService,
    CustomModifierService,
    AuthService,
    ConfigService,
    LocationService,
    PropertyService,
    OrgService,
    UserAdminService,
    SourcedataService,
    AppService,
    WeatherProvider,
    ScriptService,
    AppInfoService,
    {
          provide: HTTP_INTERCEPTORS,
          useClass: ApplicationHttpClient,
          multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
