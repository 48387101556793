import { Component, NgModule } from "@angular/core";
import { NavController, ActionSheetController, ToastController, ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import {TripService} from "../../services/trip-service";

import { ModifierService } from "../../services/modifier-service";
import { CustomModifier, CustomModifierService } from "../../services/custommodifier-service";
import { LocationService } from "../../services/location.service";
import { PropertyService } from "../../services/property.service";

import { PictureLaodPage } from "../pictureload/pictureload";
import { HomePage } from "../home/home";

import _ from "lodash";
import { PropertyDetailsModal } from "../property-details-modal/property-details-modal.component";
import { ExtendedPropertyDetailsModal } from "../extended-property-details-modal/extended-property-details-modal";
import { FieldLabelConstants } from "../../util/FieldLabelConstants";

import { ActivatedRoute } from '@angular/router';
import { ObjectId } from '@tybys/oid';

import * as $ from "jquery";

@Component({
  selector: 'page-property-detail',
  templateUrl: 'property-detail.html',
  styleUrls: ['./property-detail.scss'],

})
export class PropertyDetailPage {
  //functions
  public Math = Math;
  //gui controls:
  actionSheet: any;
  customfactorbuttons: any = [];

  // trip info
  public property: any = this.propertyService.defaultProperty();
  public factors: any = [];
  public factortypes: any = [];
  public selectedfactortype: any = { empty: true, id: -1 };
  public customfactors: any = [];
  public newcustomfactor: any = {};
  public pagestate = {
    "addcustomfactor": false
  };
  // number of adult
  public adults = 2;
  // number of children
  public children = 0;
  public priceadjustment: number = 100;

  // public customModSvc: CustomModifierService;

  public features: any = [];

  // private modifierSvc: ModifierService;
  private dummydays = Math.random();

  public location: any;

  public address: any;

  public geocodes: any[];

  public modifierlookup: any;

  private daysonmarketcache = {};

  private fieldLabels: any = (new FieldLabelConstants()).fieldLables;
  private fieldLabelKeys: any = Object.keys(this.fieldLabels);

  constructor(
    public nav: NavController,
    private activatedRoute: ActivatedRoute,
    public actionSheetCtrl: ActionSheetController,
    //public tripService: TripService,
    public modifierService: ModifierService,
    public custommodService: CustomModifierService,
    public locationService: LocationService,
    public propertyService: PropertyService,
    public modalController: ModalController,
    public toastCtrl: ToastController) {

    //Property Setup.
    this.loadData(this.activatedRoute.snapshot.paramMap.get('id'));
    // this.modifierSvc = modifierService;
    // this.customModSvc = custommodService;

  }

  private loadData(propertyId) {
    this.propertyService.findById(propertyId).subscribe(
      (property: any) => {
        //something went wrong. The alert/exception handler will do the display.
        if (!property) {
          property = this.propertyService.defaultProperty()
        }

        this.modifierlookup = _.keyBy(property.eccoval.modifiers, "_id")
        this.property = _.defaults(property, this.propertyService.defaultProperty());

        //console.log(this.property);
        if (!property.eccoval.priceadjustment) {
          property.eccoval.priceadjustment = property.eccoval.cmaprice;
        }


        if (!property.eccoval.daysonmarket) {
          this.getDaysonMarket(property);
        }

        this.features = Object.keys(this.property.features);
        this.features.sort();

        const customMods = this.property.eccoval.modifiers.filter(
          mod => {
            return mod.type === 'Custom';
        });

        this.modifierService.getAll().subscribe(modifiers => {
          this.factors = modifiers || [];
          //this.customfactors = custommodService.getAll();

          this.factors = this.factors.concat(customMods);

          this.factors = this.factors.map(element => {
            return this.resetfactors(element);
          });
          //call after the factor setups.

          this.calcAdjustedPrice(property);
          this.calcMinPrice(property);
          this.calcMaxPrice(property);

        });

        this.modifierService.getAllType().subscribe(modifiertypes => {
          this.factortypes = modifiertypes || [];
          if (this.factortypes.length) {
            this.selectedfactortype = this.factortypes[0];
            const customType = modifiertypes.find(mod => {
              return mod.custom;
            });
            if (customType) {
              customType.count = customMods.length;
            }
          }
        });


      });
  }

  public addCustomFactors() {
    let factorbuttons: any = [];

    this.factors.filter(element => {
      return !element.show;
    }).forEach(element => {
      factorbuttons.push(
        {
          text: element.name,
          handler: () => {
            this.addCustomFactor(element);
          }
        }
      );
    });
    factorbuttons.push(
      {
        text: 'Cancel',
        role: 'cancel',
        /*            handler: () => {
                    this.cancel();
                }
        */
      }
    );
    this.presentActionSheet(factorbuttons);
  }

  public presentActionSheet(actionbuttons) {
    if (this.actionSheet) {
      this.actionSheet.dismiss();
    }
    this.actionSheet = this.actionSheetCtrl.create({
      header: 'Select an Address',
      buttons: actionbuttons
    });
    this.actionSheet.present();

  }

  addCustomFactor(factor) {
    factor.show = true;
  }

  newcustomfactorsaved(modifier) {
    
    // s1v sould be negative. 
    modifier.s1v = Math.abs(modifier.s1v) * -1;

    const custMod = new CustomModifier(modifier);
    custMod.show = true;

    const customType = this.factortypes.find(mod => {
      return mod.custom;
    });
    if (customType) {
      customType.count = customType.count + 1;
    }


    this.factors.push(custMod);

    return custMod;
  }

  clearnewcustomfactor() {

  }



  calcAdjustmentFactor(factor) {
    factor.adjustment = factor['s' + Math.abs(factor.value) + 'v'] / 100;
    factor.subdesc = this.rangeValues[factor.value - 1 || 0];
    return factor;
  }

  /*
  values for 1.  Significantly below average 2.  Below average 3.  Slightly below average 4. Average 5. Slightly above average 6. Above average 7.  Significantly above average
   */
  private readonly rangeValues: string[] = [
    'Significantly below average',
    'Below average',
    'Slightly below average',
    'Average',
    'Slightly above average',
    'Above average',
    'Significantly above average'
  ];

  getFactorValue(factor, adjustment) {
    var range = Math.abs(factor.s1v) + Math.abs(factor.s7v);
    var wholeadj = ((adjustment * 100) + Math.abs(factor.s1v)) / range;
    //factor.value = 6 * wholeadj;
    //factor.subdesc = this.rangeValues[factor.value];

    return factor['s' + factor.value + 'v'];
  }

  getFiltered() {
    if (!this.selectedfactortype || this.selectedfactortype == '') {
      this.selectedfactortype = { id: 2, name: "Interior", custom: false };
    }

    let ret = [];
    ret = (!this.factors ? [] : this.factors.filter(f => {
      let res = false;

      if (f.zip == ' ') f.zip = '';
      if (f.city == ' ') f.city = '';
      if (f.state == ' ') f.state = '';

      // does this one match on 'just' the city
      if ((f.city == this.property.City || f.city == this.property.CountyOrParish) && !f.state && !f.zip) {
        res = true;
      }

      // does this one match on 'just' the state
      if (f.state == this.property.StateOrProvince && !f.zip) {
        res = true;
      }

      // does this one match on zip
      if (f.zip && Number(f.zip) == this.property.PostalCode) {
        res = true;
      }

      if (f.type === 'Custom') {
        res = true;
      }

      return res;
    }));

    ret = ret.filter(f => f.type == this.selectedfactortype.name);

    return ret;
  }


  private first_pass: boolean = true;
  calcAdjustedPrice(property) {
    // get the value that was selected
    var listprice: number = parseFloat(property.eccoval.cmaprice);
    var ret: number = parseFloat(property.eccoval.cmaprice);;

    this.factors.forEach(element => {
      ret = ret + (listprice * (element.adjustment));
    });

    property.eccoval.priceadjustment = ret;

    //daysonmarket
    // this.getDaysonMarket(property);

    if (!this.first_pass) {
      $('#cma-div').addClass('highlighted');
      $('#cma-sq-div').addClass('highlighted');

      setTimeout(() => {
        $('#cma-div').removeClass('highlighted');
        $('#cma-sq-div').removeClass('highlighted');
      }, 1000);
    } else {
      this.first_pass = false;
    }
    //return ret;
    return Math.ceil(ret);
  }

  //caching here and in the workflow since it is a bit heavy to call /KLB
  getDaysonMarket(property) {
    /*if (property.empty) {
      property.eccoval.daysonmarket = 0;
      return property.eccoval.daysonmarket;
    }
    if (this.daysonmarketcache && this.daysonmarketcache[property.eccoval.priceadjustment]) {
      property.eccoval.daysonmarket = this.daysonmarketcache[property.eccoval.priceadjustment];;
      return property.eccoval.daysonmarket;
    }

    this.daysonmarketcache[property.eccoval.priceadjustment] = 0;
    this.propertyService.getDaysonMarket(property).subscribe(
      (value: any) => {
        if (value.length > 0) {
          property.eccoval.daysonmarket = Math.round(value[0].dom ? value[0].dom : value[0]);
        } else {
          property.eccoval.daysonmarket = 24;
        }
        this.daysonmarketcache[property.eccoval.priceadjustment] = property.eccoval.daysonmarket;
      });*/
  }

  calcMinPrice(property) {
    var listprice: number = parseFloat(property.eccoval.cmaprice);
    var ret: number = listprice;

    this.factors.forEach(element => {
      if (element.show) {
        ret = ret + (listprice * (element.s1v / 100));
      }
    });

    property.eccoval.minprice = Math.floor(ret);

    return Math.floor(property.eccoval.minprice);
  }

  calcMaxPrice(property) {
    var listprice: number = parseFloat(property.eccoval.cmaprice);
    var ret: number = listprice;
    this.factors.forEach(element => {
      if (element.show) {
        ret = ret + (listprice * (element.s7v / 100));
      }
    });

    property.eccoval.maxprice = Math.ceil(ret);

    return Math.ceil(property.eccoval.maxprice);
  }

  isCustomFactor(factor) {
    let seltype = this.factortypes.filter(ftype => {
      return factor.type === ftype.name;
    });
    return (seltype && seltype.length && seltype[0].custom);
  }

  resetfactors(factor) {

    factor.show = true;

    if (factor.show) {
      this.modifierlookup = _.keyBy(this.property.eccoval.modifiers, "_id")

      const defaultvalue = (this.modifierlookup[this.getId(factor)] && this.modifierlookup[this.getId(factor)].value)

      if (defaultvalue == null) {
        // if a value is not set on the incomming data then set this to the middle value.
        factor.value = 4;
      } else {
        factor.value = defaultvalue ; //this.getFactorValue(factor, defaultvalue);
      }

      this.calcAdjustmentFactor(factor);
      return factor;
    }

  }

  getId(factor) {
    return  factor._id.toString();
  }

  formatfeature(feature) {
    return _.startCase(feature);
  }

  // view trip detail
  captureImage(id) {
    // this.nav.push(PictureLaodPage, { sourcePage: this });
    this.nav.navigateForward('picture', { state: { sourceProperty: this.property } });
  }

  saveButton() {
    return this.save();
  }

  save() {
    this.property.eccoval.modifiers = this.factors.filter(element => {
      return element.show;
    });

    this.propertyService.save(this.property).subscribe(
      (property) => {

        this.toastCtrl.create({
          message: 'Property Saved Successfully!',
          duration: 3000,
          position: 'top'
        }).then(ctl => {
          ctl.present();
          this.loadData(this.activatedRoute.snapshot.paramMap.get('id'));
        });

        //this.nav.navigateRoot('home'); stay here for now - user has to click back
      });
  }

  normalizeDisplayValue(value: string): string {
    if (value == 'False') return 'No';
    if (value == 'True') return 'Yes';

    return value;
  }

  private modalCtrl: any;

  viewExtendedPropertyDetails() {
    this.modalController.create({
      component: ExtendedPropertyDetailsModal,
      componentProps: { property: this.property },
      cssClass: 'epd-modal'
    }).then((ctl) => {

      ctl.onDidDismiss().then(data => {
        // reload the property from the db
        // this.property = data.dismissed;
        this.propertyService.findById(this.activatedRoute.snapshot.paramMap.get('id'));
      });
      ctl.present();
    });

  }
}
