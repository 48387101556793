import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { catchError, retry, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { NavController, Events } from '@ionic/angular';
import { throwError } from 'rxjs';

const APP_ERROR_STATUS = 418;

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}


@Injectable()
export class ApplicationHttpClient {

    private readonly configService: ConfigService = new ConfigService();

    // Extending the HttpClient through the Angular DI.
    public constructor(public http: HttpClient, public events: Events, private navCtrl: NavController) {
        // If you don't want to use the extended versions in some cases you can access the public property and use the original one.
        // for ex. this.httpClient.http.get(...)
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.startsWith('http')) {
        request = request.clone({ url: this.configService.getSvcURL(request.url) });
    }

    if (request.method === 'GET') {
        request['retryCount'] = 3;
      } else {
        request['retryCount'] = 0;
      }


    return next.handle(request).pipe(retry(request['retryCount']),
      // most of the time the error will be shown by th calling methods, which will replace the stock error that might be shown by this.
      catchError((err: any) => {
          return this.catchErrors(err, this.events);
      }));
  }
    /**
     * DELETE request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}

    public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.delete<T>(this.configService.getSvcURL(endPoint), options)
        .catch((e: any) => this.catchErrors(e, this.events));
    }
    */

    private catchErrors(err: any, events: Events) {
        // Catch all errors. Network errors restul in status < 1
        console.log('got error: ');
        console.log(err);
        // if ((err.status || err.error.status) > 399 ||  (err.status || err.error.status) < 1) {
        if ((err.status || err.error.status) == APP_ERROR_STATUS) {
            events.publish('service.exception', err.error);
        } else if ((err.status || err.error.status) > 400 && (err.status || err.error.status) < 404 && err.url.indexOf('signup') === -1) {
            // handle authorization errors
            // in this example I am navigating to login.
            if (!err.error || !err.error.path || !err.error.path.includes('/api/auth/authorize')) {
              events.publish('service.exception', err.error);
            }
            console.log('LOGIN_Error(' + err.status + '): redirecting to login.');
            localStorage.removeItem('token');
            this.navCtrl.navigateRoot('');
            // make sure errors are shown to the user.
        } else if ((err.status || err.error.status) > 400 ||  (err.status || err.error.status) < 420 && err.url.indexOf('signup') === -1) {
                    // return this.navCtrl.navigateRoot('');
                    events.publish('service.exception', err.error);
        } else if (err.error && err.url.indexOf('signup') === -1) {
              events.publish('service.exception', err.error);
              // return events.publish('user:logout');
        }
        console.log('App HTTP Log: ' + err.statusText);
        return throwError(err);
        // return Observable.throw(err);
    }
}
